.mgl-flex {
    display: flex;
}

.mgl-flex-growAndShrink{
    flex:1;
}

.mgl-flex-justifyContent-spaceBetween{
    justify-content: space-between;
}

.mgl-flex-justifyContent-spaceAround{
    justify-content: space-around;
}

.mgl-flex-justifyContent-center{
    justify-content: center;
}

.mgl-flex-justifyContent-flexEnd{
    justify-content: flex-end;
}

.mgl-flex-alignItems-flexStart{
    align-items: flex-start;
}

.mgl-flex-alignItems-center{
    align-items: center;
}

.mgl-flex-alignItems-flexStart{
    align-items: flex-start;
}

.mgl-flex-flexWrap-wrap{
    flex-wrap: wrap;
}